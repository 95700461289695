a {
    text-decoration: none;
    color: $primary;
    transition: all 0.25s linear;

    &:hover {
        color: $green;
    }
}

ul {
    margin: 0;
    list-style-type: none;
    padding: 0;

    li {
        margin: 0;
        list-style: none;
    }
}

p {
    margin: 0;
    @include font-style($Plex, 16px, 22px, normal);
    color: $primary;
}

.center-text {
    text-align: center;
}

.page {

    #root,
    .App,
    .main {
        height: 100%;
    }


}

.color-primary {
    color: $primary;
}

picture {
    display: block;
}

.main-container {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding-left: 24px;
    padding-right: 24px;
}

.custom-container {
    // max-width: 1488px;
    // margin: 0 auto;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
        padding-left: 75px;
        padding-right: 75px;
    }
}

div {
    .card {
        width: 100%;
        height: 100%;
        padding: 24px;
        border: none;
        border-radius: 10px;
        background: $grey;

        &__header {
            margin-bottom: 24px;
            display: flex;
            align-items: center;
        }

        @include media-breakpoint-up(sm) {
            .h4-title {
                min-height: 100px;
            }
        }

        @include media-breakpoint-up(lg) {
            .h4-title {
                min-height: 75px;
            }
        }

        &__avatar {
            width: 60px;
            height: 60px;
            margin: 0 10px 0 0;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-shrink: 0;
            font-size: 24px;
            background: rgba($green, .15);
            color: $green;

            .icon-health {

                &:before {
                    display: none;
                }

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }

        &__tag {
            padding: 8px 10px;
            border: 1px solid rgba($green, .25);
            border-radius: 20px;
            font-size: 13px;
            line-height: 13px;
            background: rgba($green, .15);
            text-align: center;

            .bg-green & {
                color: $primary;
                background: rgba(255, 255, 255, 0.24);
                border: 1px solid rgba(255, 255, 255, 0.24);
            }
        }

        &__buttons {
            a {
                &:first-child {
                    margin-bottom: 10px;
                }
            }
        }
    }


}

.container-slick {
    max-width: 268px;

    @include media-breakpoint-up(md) {
        max-width: 520px;
    }
    

    .slick-slide {
        max-width: 268px;
        padding-right: 16px;
    }

    .slick-list {
        overflow: visible;
    }
}

.defis-content {
    .slick-slide {
        max-width: 268px;
        padding-right: 16px;
    }

    // .slick-list {
    //   overflow: visible;
    // }
}

.tag-cog {
    padding: 8px 10px;
    border-radius: 20px;
    @include font-style($Plex, 11px, 11px, normal);
    color: $primary;
    display: block;
    text-align: center;
}

.item-icon-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 42px;
    color: $white;
    z-index: 1;
}

.slick-item {
    border-radius: 10px;
    overflow: hidden;
    max-width: 252px;
    min-height: 345px;

    &__img {
        position: relative;
    }

    &__tag {
        position: absolute;
        top: 10px;
        left: 10px;
    }

    &__content {
        padding: 24px 24px 34px;
    }
}


.card-border-0 {
    border: none;
}

div {
    .bg-white {
        background: $white;
    }

    .bg-gray {
        background: $grey;
    }

    .bg-green {
        background: $green;

        .card__avatar {
            background: $white;
        }
    }

    .bg-pale-green {
        background: rgba($green, 0.15);

        .card__avatar {
            background: $white;
        }
    }
}

.color-green {
    color: $green;
}

.color-yellow {
    color: $yellow;
}

.color-red {
    color: $red2;
}

.card-wrapper {
    margin-bottom: 24px;
}

.section-welcome {
    margin: 0 0 32px;
    padding: 30px;
    border-radius: 10px;
    background: $green;

    @include media-breakpoint-up(lg) {
        padding: 36px 54px;
        display: flex;
    }

    &__picture {
        padding: 0 0 30px;

        @include media-breakpoint-up(lg) {
            padding: 0 48px 0 0;
            flex-shrink: 0;
        }
    }
}

.message-cogx {
    .message__title {
        margin: 0 0 9px;
    }
}

div.modal {
    z-index: 1055;
    color: $primary;
}

.modal-footer__link {
    text-decoration: underline;
    margin-right: 24px;
    cursor: pointer;
}

.slick-track {
    display: flex;

    .slick-slide {
        height: auto;
        margin-bottom: 16px;

        >div {
            height: 100%;
        }
    }

    .slick-item {
        height: 100%;

        .modal & {
            background: $grey;
        }
    }
}

.slick-slider {
    margin-bottom: 30px;
}

.back-btn {
    margin-bottom: 20px;
    display: block;
    @include font-style($Plex, 16px, 16px, 700);
    cursor: pointer;
}

.slick-dots {
    li {
        button {
            &:before {
                color: $primary;
                opacity: 1;
            }
        }

        &.slick-active
            button {

            &:before {
                color: $green;
                opacity: 1;
            }
        }
    }
}

.eye {
    position: absolute;
    top: 28px;
    right: 0px;
    width: 40px;
    height: 46px;
    border-left: 1px solid $input-border;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-eye-open, .icon-eye-close  {
    cursor: pointer;
}