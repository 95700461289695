@import "../../scss/custom-bootstrap/functions";
@import "../../scss/custom-bootstrap/variables";
@import "../../scss/custom-bootstrap/mixins";
@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/base";

.btn-primary {
    @include font-style($Plex,16px,22px,800);
    color: $white;

    &.btn {
        border-radius: 4px;
        padding: 17px 14px;
        border: none;

        &.btn-back {
            border: 1px solid $green;
            color: $green;
            background: $white;
            padding: 11px 20px 13px;
            margin: 0;
            min-width: 48%;
        }

        &.btn-apply {
            padding: 11px 20px 13px;
            margin: 0;
            min-width: auto;

            .modal-footer & {
                min-width: 48%;
            }
        }

        &.btn--sucess {
            padding: 14px 20px;
        }

        &--full {
            width: 100%;
        }
    }

    &.btn-green {
        background: $green;

        &:hover {
            background: $green-hover;
        }

        &:focus {
            background: $green-hover;
            border: none;
        }
    }
}

.btn {
    min-width: 156px;
    
    &.btn-secondary {
        border-radius: 4px;
        padding: 17px 14px;
        border: 1px solid $green;
        background: none;
        color: $green;
    }

    &.btn-white {
        background: $white;
        border: none;
        @include font-style($Plex,18px,18px,$bold);
        color: $primary;
        padding: 14px 52px;

        &:hover, &:focus {
            background: $white;
        }
    }

    &.btn-small {
        @include font-style($Plex,16px,22px,$bold);
        padding: 7px 14px; 
        border: 1px solid transparent;
    }

    &--outline {
        @include font-style($Plex,16px,16px,$bold);
        color: $green;
        padding: 14px 20px;
        border: 1px solid $green;

        &:hover, &:focus {
            color: $green;
        }
    }

    &--cancel {
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        text-decoration: underline;
        min-width: auto;
        padding: 0;
        margin-top: 12px;
    }

    &--memo {
        min-width: 265px;
    }

    &--full {
        width: 100%;
    }

    &--new {
        padding: 16px 20px;
    }

    &--center {
        display: block;
        margin: 0 auto;

        @include media-breakpoint-up(sm) {
            margin-top: 20px;
        }
    }

    &:focus {
        box-shadow: none;
        outline: none;
    }

    .badge {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border: 1px solid $primary;
        @include font-style($Plex,12px,12px,$bold);
        color: $white;
        background: $primary !important;
        padding-bottom: 6px;
        margin-left: 8px;
        width: 25px;
        height: 25px;
        border-radius: 50%;
    }
}

.btn-filter {
    display: flex;
    justify-content: center;
    align-items: center;
}

