@import "../../pages/login/Login.scss";

.mdp {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.btn-connect {
    margin-top: 10px;
}