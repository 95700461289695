@import "../scss/custom-bootstrap/functions";
@import "../scss/custom-bootstrap/variables";
@import "../scss/custom-bootstrap/mixins";
@import "../scss/variables";
@import "../scss/mixins";
@import "../scss/base";

.cookie-notice {
  p {
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  .btn {
    margin-bottom: 1rem;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }
}
