@font-face {
    font-family:'IBM-PlexMono-Bold';
    src:url('../../public/assets/fonts/IBMPlexMono-Bold.eot');
	src: url('../../public/assets/fonts/IBMPlexMono-Bold.eot?#iefix') format('embedded-opentype'),
		url('../../public/assets/fonts/IBMPlexMono-Bold.woff2') format('woff2'),
		url('../../public/assets/fonts/IBMPlexMono-Bold.woff') format('woff'),
		url('../../public/assets/fonts/IBMPlexMono-Bold.svg#IBM Plex Sans Bold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2084;
}

@font-face {
    font-family: 'poppins-regular';
    src: url('../../public/assets/fonts/poppins/Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'poppins-semibold';
    src: url('../../public/assets/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'poppins-medium';
    src: url('../../public/assets/fonts/poppins/Poppins-Medium.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;

}




// @font-face {
//     font-family:'IBM-Plex-Sans-bold';
//     src:url('../../public/assets/fonts/IBM Plex Sans Bold.eot');
// 	src: url('../../public/assets/fonts/IBM Plex Sans Bold.eot?#iefix') format('embedded-opentype'),
// 		url('../../public/assets/fonts/IBM Plex Sans Bold.woff2') format('woff2'),
// 		url('../../public/assets/fonts/IBM Plex Sans Bold.woff') format('woff'),
// 		url('../../public/assets/fonts/IBM Plex Sans Bold.svg#IBM Plex Sans Bold') format('svg');
//     font-weight: 800;
//     font-style: normal;
//     font-stretch: normal;
//     unicode-range: U+0020-2084;
// }

// @font-face {
//     font-family:'IBM-Plex-Sans-regular';
//     src: url('../../public/assets/fonts/IBM Plex Sans Regular.eot');
// 	src: url('../../public/assets/fonts/IBM Plex Sans Regular.eot?#iefix') format('embedded-opentype'),
// 		url('../../public/assets/fonts/IBM Plex Sans Regular.woff2') format('woff2'),
// 		url('../../public/assets/fonts/IBM Plex Sans Regular.woff') format('woff'),
// 		url('../../public/assets/fonts/IBM Plex Sans Regular.svg#IBM Plex Sans Regular') format('svg');
//     font-weight: 400;
//     font-style: normal;
//     font-stretch: normal;
//     unicode-range: U+0020-2084;
// }

// @font-face {
//     font-family:'IBM-Plex-Sans-Medium';
//     src: url('../../public/assets/fonts/IBM Plex Sans Medium.eot');
// 	src: url('../../public/assets/fonts/IBM Plex Sans Medium.eot?#iefix') format('embedded-opentype'),
// 		url('../../public/assets/fonts/IBM Plex Sans Medium.woff2') format('woff2'),
// 		url('../../public/assets/fonts/IBM Plex Sans Medium.woff') format('woff'),
// 		url('../../public/assets/fonts/IBM Plex Sans Medium.svg#IBM Plex Sans Medium') format('svg');
//     font-weight: 500;
//     font-style: normal;
//     font-stretch: normal;
//     unicode-range: U+0020-2084;
// }
