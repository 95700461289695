.filter-modal {
    padding-left: 0 !important;

    .modal-content {
        border-radius: 0;
        border: none;
        padding: 24px;
    }

    .modal-header, .modal-body, .modal-footer {
        padding: 0;
        border: none;
    }

    .modal-header {
        position: relative;
    }

    .modal-title {
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 24px;
    }

    .btn-close {
        padding: 0;
        width: 16px;
        height: 16px;
        background: url('../../public/assets/images/close-filter.svg') no-repeat 0 0;
        opacity: 1;
        position: absolute;
        top: 23px;
        right: 10px;
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
        padding-top: 22px;
    }
}

.filter-by {
    padding: 16px 8px 0;
    display: flex;
    flex-wrap: wrap;
    background: $white;
    border-radius: 8px;
    margin-bottom: 12px;

    @include media-breakpoint-up(lg) {
        padding: 24px 16px 16px;
        margin-bottom: 24px;
        background: $grey;
    }

    > div {
        margin: 0 8px 8px 0;

        p {
            @include font-style($Plex,12px,12px,normal);
            color: $text-grey;
            padding: 4px 12px 5px;
            background: $white;
            border: 1px solid $green2;
            border-radius: 20px;
            text-align: center;
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            height: 26px;

            @include media-breakpoint-up(lg) {
                font-size: 16px;
                line-height: 16px;
                padding: 3px 16px 6px;
                height: 42px;
            }

            span {
                display: none;
            }
        }

        .micro-selected {
            color: $white;
            background: $green;
            border: 1px solid $green;
            padding: 4px 3px 5px 12px;

            @include media-breakpoint-up(lg) {
                padding: 3px 8px 6px 16px;
            }

            span {
                display: inline-flex;
            }
        }
    }

    &__close {
        display: inline-flex;
        width: 24px;
        height: 23px;
        background: url('../../public/assets/images/close-multiply.svg') no-repeat 0 0;

        @include media-breakpoint-up(lg) {
            height: 21px;
        }
    }
}

.info-filter-modal {
    .modal-body {
        .deselect {
            background: none;
        }
    }

    .deselect {
        @include font-style($Plex,16px,16px,600);
        color: $green;
        padding: 0;
        text-decoration: underline;
        background: none;
        margin: 0 auto;
        display: block;
        opacity: 1;

        @include media-breakpoint-up(lg) {
            margin: 0;
        }

        &:hover {
            text-decoration: none;
            background: none;
        }
    }
}