.btn-primary {
    @include font-style($Plex, 16px, 22px, 800);
    color: $white;
    &.btn {
        border-radius: 4px;
        padding: 17px 14px;
        border: none;
    }
    &.btn-green {
        background: $green;
        &:hover {
            background: $green-hover;
        }
        &:focus {
            background: $green-hover;
            border: none;
        }
    }
}

.btn-secondary {
    @include font-style($Plex, 16px, 22px, 800);
}

.btn {
    &:focus {
        box-shadow: none;
        outline: none;
    }

    &-filter {
        font-size: 14px;
        font-weight: 700;
        min-width: 87px;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            padding: 11px 10px;
            min-width: 120px;
        }
    }
}

.btn-small {
    @include font-style($Plex, 16px, 22px, 800);
    padding: 7px 14px;
    width: 100%;
}

.btn-outline {
    border: 1px solid $grey2;
    color: $green;
    background: transparent;
}

.btn-green {
    color: $white;
    background: $green;
    &:hover {
        color: $white;
        background: $green-hover;
    }
    &:focus {
        background: $green-hover;
        border: none;
    }
}

.btn-white {
    color: $green;
    background: $white;
    &:hover {
        background: $green-hover;
    }
    &:focus {
        background: $green-hover;
        border: none;
    }
}
