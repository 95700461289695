// font
@mixin font-style($font-family, $font-size, $line-height, $font-weight) {
    font-family: $font-family;
    font-size: $font-size;
    line-height: $line-height;
    font-weight: $font-weight;
}

@mixin input-placeholder {
    &::-webkit-input-placeholder {
        color: $text-input;
        opacity: 1;
    }
  
    &::-ms-input-placeholder {
        color: $text-input;
        opacity: 1;
    }
  
     &::placeholder {
        color: $text-input;
        opacity: 1;
    }
}

@mixin responsive-up($breakpoint) {
    @media only screen and (min-width: $breakpoint) {
        @content;
    }
  }