.message-cogx {
    &__wrap {
        display: flex;
        align-items: center;
        flex-direction: column;

        @include media-breakpoint-up(lg) {
            flex-direction: row;
        }
    }

    &__content {
        margin-bottom: 15px;

        @include media-breakpoint-up(lg) {
            padding-right: 150px;
            margin: 0;
        }
    }

    &__filter {
        display: flex;
        align-items: center;
        flex-shrink: 0;

        .challenges-recommends & {
            justify-content: flex-end;
            margin-bottom: 32px;
            position: absolute;
            right: 12px;
            top: -58px;

            @include media-breakpoint-up(xl) {
                position: static;
            }
        }
    }
}

.icon-filter {
    display: inline-block;   
    width: 21px;
    height: 20px;
    background: url('../../public/assets/images/filter.svg') no-repeat 0 0;
    margin-right: 10px;

    .challenges-recommends & {
        width: 18px;
        height: 18px;
        background: url('../../public/assets/images/filter-cogx.svg') no-repeat 0 0;
        background-size: 100%;
        margin: 0 0 0 5px;

        @include media-breakpoint-up(lg) {
            width: 24px;
            height: 24px;
        }
    }
}

.title-spinner {
    display: flex;
    align-items: center;

    .spinner-grow {
        margin: 5px 0 0 1px;
        display: inline-flex;
        flex-shrink: 0;

        @include media-breakpoint-up(lg) {
            margin: 5px 0 0 10px;
        }
    }
}

.bilan-links {
    display: inline-flex;
    justify-content: space-between;
    background: $grey;
    border-radius: 50px;
    margin-bottom: 34px;
    width: 100%;

    @include media-breakpoint-up(sm) {
        width: auto;
        justify-content: flex-start;
    }

    a {
        font-size: 14px;
        line-height: 14px;
        color: $text-input;
        padding: 10px 8px;
        min-width: 156px;
        text-align: center;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 16px; 
            margin-right: 16px;
            padding: 10px 16px;
            min-width: auto;
        }

        &:last-child {
            margin: 0;
        }
    }

    .active {
        color: $primary;
        background: $green;
        border-radius: 50px;
    }
}