.h1-title {
    @include font-style($Plex,22px,30px,800);
    color: $primary;
    margin: 0 0 20px;
    @include media-breakpoint-up(sm) {
        @include font-style($Plex,26px,40px,800);
    }

    @include media-breakpoint-up(xl) {
        margin: 0 0 30px;
        font-size: 36px;
        line-height: 50px;
    }

    &.title-green {
        color: $green;
    }
}

.h2-title {
    @include font-style($Plex,22px,30px,800);
    color: $primary;
    margin: 0 0 20px;

    @include media-breakpoint-up(sm) {
        @include font-style($Plex,26px,40px,800);
        margin: 0 0 30px;
    }
    @include media-breakpoint-up(lg) {
        font-size: 30px;
        line-height: 40px;
    }
}


.h3-title {
    @include font-style($Plex,18px,26px,800);
    color: $primary;
    margin: 0 0 30px;

    @include media-breakpoint-up(lg) {
        font-size: 25px;
        line-height: 30px;
    }
}

.h4-title {
    @include font-style($Plex,18px,25px,800);
    color: $primary;
    margin: 0 0 20px;
}

.h5-title {
    @include font-style($Plex,20px,30px,800);
    color: $primary;
    margin: 0 0 24px;

    @include media-breakpoint-up(lg) {
        font-size: 24px;
        line-height: 36px;
    }
}

.h6-title {
    @include font-style($Plex,18px,25px,700);
}

.text-center {
    text-align: center;
}