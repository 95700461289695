@import "../../scss/custom-bootstrap/functions";
@import "../../scss/custom-bootstrap/variables";
@import "../../scss/custom-bootstrap/mixins";
@import "../../scss/variables";
@import "../../scss/mixins";
@import "../../scss/typography";
@import "../../scss/icomoon";
@import "../../scss/heading";
@import "../../scss/input";
@import "../../scss/buttons";
@import "../../scss/base";

.login {
    display: flex;

    // @media (min-width: $desktop-sm) and  (min-height: 768px){
    //     align-items: center;
    // }
    


    &__form {
        width: 100%;

        @include media-breakpoint-up(lg) {
            width: 65%;
            height: 100vh;
            overflow-y: auto;
        }

        @media (min-width: $desktop-sm) and  (min-height: 768px){
            display: flex;
            align-items: center;
        }

        @include media-breakpoint-up(xxl) {
            width: 50%;
        }

        &.login-forgot {
            @include media-breakpoint-down(lg) {
                display: flex;
                align-items: center;
                height: 100vh;
            }
            .form-login__desc {
                @include media-breakpoint-up(lg) {
                    padding: 0;
                }
            }
            .form-control {
                @include font-style($Plex,14px,14px,normal);
                color: $text-input;
                padding: 14px 12px;
                border: 1px solid $input-border;
                width: 100%;
                border-radius: 4px;

                &:focus {
                    box-shadow: none;
                    border-color: $primary;
                }
                &.input-error {
                    border-color: $red;
                }
            }
            .btn-connect {
                margin-top: 0;
            }
        }
    }

    &__bg {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        display: none;

        @include media-breakpoint-up(lg) {
            display: flex;
            height: 100vh;
            width: 35%;
        }

        @include media-breakpoint-up(xxl) {
            width: 50%;
        }

        img {
            max-width: 100%;
            height: auto;
        }

        &__image {
            width: 100%;
            position: relative;
            z-index: 2;

            img {
                width: 100%;
                object-fit: cover;
            }
        }
        &.bg-yellow {
            background-color: $yellow;
        }
        &.bg-green {
            background-color: $green;
        }
        &.bg-purple {
            background-color: $primary;
        }

        picture {
            transform: scale(0.6);
            @include media-breakpoint-up(xl) {
                transform: scale(0.75);
            }
            @media (min-width: $desktop-md) {
                transform: scale(0.9);
            }

            @media (min-width: $desktop-lg) {
                transform: scale(1);
            }
        }
    }
}

// .top {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 50%;
//     background: $green;
//     z-index: 1;
// }

// .bottom {
//     position: absolute;
//     bottom: 0;
//     left: 0;
//     width: 100%;
//     height: 50%;
//     background: $yellow;
//     z-index: 1;
// }

.form-login {
    width: 100%;
    margin: 0 auto;
    padding: 100px 20px 50px;
    
    @include media-breakpoint-up(lg) {
        padding: 64px 20px;
    }

    @include media-breakpoint-up(xl) {
        max-width: 80%;
    }

    @include media-breakpoint-up(xxl) {
        padding: 100px 20px 46px;
        width: 528px;
    }

    .h1-title {
        @include media-breakpoint-up(sm) {
            margin-bottom: 20px;
            font-size: 26px;
            line-height: 40px;
        }

        @media (min-width: $desktop-sm) and  (min-height: 768px){
            margin-bottom: 30px;
            font-size: 36px;
            line-height: 50px;
        }
    }

    &__desc {
        @include font-style($Plex-Mono-bold,14px,1.75,normal);
        color: $primary;
        margin-bottom: 25px;
        padding: 0;
        
        @media (min-width: $desktop-sm) and  (min-height: 768px){
            @include font-style($Plex-Mono-bold,18px,28px,normal);
            // padding: 0 30px;
            margin-bottom: 40px;
        }
    }
}

.btn-connect {
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    @include media-breakpoint-up(xxl) {
        margin-bottom: 40px;
    }
    .btn {
        width: 100%;
    }

    .loader {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
    }
}

.no-account {
    @include font-style($Plex,14px,18px,400);
    color: $primary;
    text-align: center;
    position: relative;
    @include media-breakpoint-up(sm) {
        @include font-style($Plex,16px,22px,normal);
    }
    &:before {
        content: '';
        display: block;
        padding-top: 20px;
        border-top: 1px solid $input-border;

        @include media-breakpoint-up(xxl) {
            padding-top: 40px;
        }
    }

    a {
        @include font-style($Plex,16px,22px,800);
        color: $green;
        &:hover {
            color: $primary;
        }
    }
}

.forget {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px 0;
    @include media-breakpoint-up(sm) {
        margin: 20px 0;
    }
    &__password {
        display: inherit;
        a {
            @include font-style($Plex,13px,17px,800);
            color: $primary;
            text-decoration: underline;
            &:hover {
                text-decoration: none;
            }
            @include media-breakpoint-up(sm) {
                @include font-style($Plex,14px,19px,800);
            }
        }
    }
}

.loader {
    display: flex;
    justify-content: center;

    .spinner-border {
        border-color: $green;
        border-right-color: transparent;
    }
}