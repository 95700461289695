@import '../../../scss/custom-bootstrap/functions';
@import '../../../scss/custom-bootstrap/variables';
@import '../../../scss/custom-bootstrap/mixins';
@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../scss/modal';
@import '../../../scss/filter';

.footer-logout {
  justify-content: center;
}