  @font-face {
    font-family: 'icomoon';
    src:  url('../../public/assets/fonts/icomoon.eot?vj27vq');
    src:  url('../../public/assets/fonts/icomoon.eot?vj27vq#iefix') format('embedded-opentype'),
      url('../../public/assets/fonts/icomoon.ttf?vj27vq') format('truetype'),
      url('../../public/assets/fonts/icomoon.woff?vj27vq') format('woff'),
      url('../../public/assets/fonts/icomoon.svg?vj27vq#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-play-video:before {
    content: "\e90b";
  }
  .icon-next:before {
    content: "\e909";
  }
  .icon-prev:before {
    content: "\e90a";
  }
  .icon-info:before {
    content: "\e900";
  }
  .icon-hands:before {
    content: "\e901";
  }
  .icon-star:before {
    content: "\e902";
  }
  .icon-logout:before {
    content: "\e903";
  }
  .icon-eye-open:before {
    content: "\e904";
  }
  .icon-eye-close:before {
    content: "\e905";
  }
  .icon-work:before {
    content: "\e906";
  }
  .icon-mode:before {
    content: "\e907";
  }
  .icon-health:before {
    content: "\e908";
  }
  
  
  