$white: #fff;
$primary: #211046; 
$yellow: #FFC33C;
$green: #27D1C4;
$green-hover: #24B5A7;
$green1: #3DBC1E;
$green2: #BEF1ED;
$grey: #F7F7FC;
$grey1: #E7E5EB;
$grey2: #cfcdb8;
$grey3: #7b7b7b;
$grey4: #9799b1;
$grey5:#d9d9d9;
$grey6: #F5F5F5;
$input-border: #CFCBD8;
$text-input: #8A80A0;
$red: #FF4E4E;
$red2: #E43232;
$blue: #3CA1FF;
$text-grey: #323232;
$violet: #7062C9;
$light-purple: #4A3677;

$Plex-regular: 'IBM-Plex-Sans-regular';
$Plex-medium: 'IBM-Plex-Sans-Medium';
$Plex-bold: 'IBM-Plex-Sans-bold';
$Plex-Mono-bold : 'IBM-PlexMono-Bold';
$poppins-regular: 'poppins-regular';
$poppins-medium: 'poppins-medium';
$poppins-semibold: 'poppins-semibold';


$Plex: 'ibm-plex-sans';

// breakpoint custom 
$desktop-sm: 992px;
$desktop-md: 1400px;
$desktop-lg: 1680px;

$regular: 400;
$bold: 700;