@import "../../scss/variables";
@import "../../scss/mixins";

.form-radio {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
        @include font-style($Plex,14px,19px,normal);
        margin: 0;
    }

    input {
        margin-right: 8px;
    }
}