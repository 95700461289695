
label {
    @include font-style($Plex,14px,18px,800);
    color: $primary;
    width: 100%;
    margin-bottom: 2px;
    @include media-breakpoint-up(sm) { 
        @include font-style($Plex,16px,22px,800);
    }
}

input[type="text"],input[type="password"] {
    @include font-style($Plex,14px,18px,400); 
    @include media-breakpoint-up(sm) { 
        @include font-style($Plex,16px,22px,normal);
    }
}

input.form-control {
    @include font-style($Plex,14px,14px,normal);
    color: $text-input;
    padding: 14px 12px;
    border: 1px solid $input-border;
    width: 100%;
    border-radius: 4px;

    &:focus {
        box-shadow: none;
        border-color: $primary;
    }
    &.input-error {
        border-color: $red;
    }
}

.input-error {
    border-color: $red;
}

.form-group {
    margin-bottom: 10px;
    position: relative;
    @include media-breakpoint-up(sm) {
        margin-bottom: 18px;
    }

    .info-engaged-modal & {
        margin-bottom: 23px;
    }

    .form-control {
        padding:  13px 8px;
        border-color: $input-border;
        @include input-placeholder;

        &:focus {
            box-shadow: none;
        }
    }

    .textarea {
        @include font-style($Plex,14px,20px,500);
        color: $text-grey;
        min-height: 103px;
        @include input-placeholder;

        @include media-breakpoint-up(lg) {
            font-size: 16px;
            line-height: 22px;
        }
    }

    .label-desc {
        @include font-style($Plex,12px,14px,500);
        color: $text-grey;
        margin-bottom: 6px;

        @include media-breakpoint-up(lg) {
            font-size: 12px;
            line-height: 16px;
          }
    }
}

.form-item {
    margin-bottom: 23px;
}

.text-danger {
    @include font-style($Plex,13px,13px,normal);
    color: $red;
    margin-top: 5px;
}

//Input Checkbox
input[type="checkbox"] {
    &:checked,
    &:not(:checked) {
    position: absolute;
    top: 2px;
    left: 0;
    width: 8px;
    z-index: 1;
    height: 8px;
    opacity: 0;
    cursor: pointer;

    + label {
        cursor: pointer;
        display: inline-block;
        @include font-style($Plex, 14px, 18px, normal);
        color: $primary;
        letter-spacing: normal;
        position: relative;
        padding-left: 22px;
        @include media-breakpoint-up(sm) { 
            @include font-style($Plex, 16px, 22px, normal);
        }


    .register & {
        @include font-style($Plex, 12px, 16px, normal);
        @media (min-width: $desktop-md) {
            @include font-style($Plex, 13px, 17px, normal);
        }
        @media (min-width: $desktop-lg) {
            @include font-style($Plex, 12px, 16px, normal);
        }
    }

        &:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0;
            margin: auto;
            width: 14px;
            height: 14px;
            border: 1px solid $input-border;
            background: none;
            border-radius: 2px;
        }

        &:after {
            content: '';
            width: 14px;
            height: 14px;
            background: url('../../public/assets/images/tick-check.svg');
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            transition: all 0.2s ease;
        }
    }
    }

    &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    &:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    &:checked + label:before {
        border: 1px solid $input-border;
    }
}

//Input Radio
input[type="radio"] {
    &:checked,
    &:not(:checked) {
    position: absolute;
    top: 2px;
    left: 0;
    width: 8px;
    z-index: 1;
    height: 8px;
    opacity: 0;
    cursor: pointer;

    + label {
        cursor: pointer;
        display: inline-block;
        @include font-style($Plex, 14px, 20px, normal);
        color: $primary;
        letter-spacing: normal;
        position: relative;
        padding-left: 22px;

        &:before {
            content: '';
            position: absolute;
            left: 0px;
            top: 0px;
            bottom: 0;
            margin: auto;
            width: 14px;
            height: 14px;
            border: 1px solid $input-border;
            background: none;
            border-radius: 50%;
        }

        &:after {
            content: '';
            width: 14px;
            height: 14px;
            background: url('../../public/assets/images/radio-check.svg');
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            margin: auto;
            transition: all 0.2s ease;
        }
    }
    }

    &:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    &:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    &:checked + label:before {
        border: 1px solid $input-border;
    }
}