@import '../../../scss/custom-bootstrap/functions';
@import '../../../scss/custom-bootstrap/variables';
@import '../../../scss/custom-bootstrap/mixins';
@import '../../../scss/variables';
@import '../../../scss/mixins';
@import '../../../scss/typography';
@import '../../../scss/icomoon';
@import '../../../scss/heading';
@import '../../../scss/input';
@import '../../../scss/buttons';
@import '../../../scss/base';
@import '../../../scss/modal';
@import '../../../scss/filter';

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.main-content {
  padding: 100px 0;
}

.header-top {
  margin-bottom: 40px;
}

.heading {
  @include font-style($Plex, 36px, 50px, 700);
  color: $green;

  @include media-breakpoint-up(lg) {
    font-size: 48px;
    line-height: 67px;
  }
}

p {
  font-size: 1.2rem;
  color: #6c757d;
}

.card {
  padding: 20px 24px;
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    transform: translateY(-5px);
  }

  &-img-top {
    width: 100%;
    max-width: 207px;
    max-height: 200px;
    object-fit: contain;
    margin-bottom: 30px;
  }

  &-body {
    padding: 20px;
  }

  &-title {
    @include font-style($Plex, 24px, 36px, 700);
    margin-bottom: 30px;
  }

  &-btn {
    margin-top: auto;
  }
}

.box {
  padding: 15px;
  border-radius: 8px;

  &-title {
    margin-bottom: 20px;
    @include font-style($Plex, 24px, 50px, 700);

    @include media-breakpoint-up(lg) {
      font-size: 36px;
    }
  }
}
